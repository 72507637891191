/*** FOOTER STYLES ***/
.site-footer{
  background: #ffffff;
  border-top: 1px solid #e7e7e7;
  padding: 20px;
  .single-ui-footer-container{
    max-width: 1824px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 48px;
  }
  .btn-links{
    margin-top: 7px;
  }
}
