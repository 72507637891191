//Auto Style Checkbox Styling

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.checkbox + .checkbox {
  margin-top: -5px;
}

.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="checkbox"] {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="checkbox"] {
  margin: 4px 0 0;
}

input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

input[type=checkbox] {
  position: relative;

  &::before {
    position: absolute;
    color: rgba($navisphereBlue, 0.0);
    left: -1px;
    top: -2px;
    width: 16px;
    height: 16px;
    border: 1px solid rgba($input-border-gray, 1.0);
    cursor: pointer;
    opacity:1;
  }

  &:not(:disabled) {

    &::before {
      background: rgba($white, 1.0);
    }

    &:checked::before {
      color: rgba($navisphereBlue, 1.0);
      border-color: rgba($navisphereBlue, 1.0);
    }

  }

  &:disabled {

    &::before {
      background: rgba($disabled-gray, 1.0);
      cursor: not-allowed;
    }

  }

}

input[type=checkbox] {

  &::before {
    content: '\2714';
    border-radius: 3px;
  }

  &:not(:disabled) {

    &:checked::before {
      font-size: 14px;
      font-weight: bolder;
      line-height: 1;
      padding-left: 0.5px;
    }

  }

}

.checkbox {

  &:nth-child(1) {
    margin-top: 0;
  }

  &.disabled {
    color: rgba($placeholder-gray, 1.0);
    cursor: not-allowed;
  }

}

.checkbox-inline {
  margin-right: 10px;
}

label {

  .ns-icon.ns-info {

    &::before {
      font-size: 120%;
      cursor: pointer;
    }

  }

  &.required {
    position: relative;

    &::after {
      background: transparent;
      color: $error-red;
      font-size: 12px;
      padding-left: 5px;
      line-height: 15px;
      top: 5px;
      left: 0;
      border-radius: 50%;
      content: '*';
    }

  }
}

.error-message{
  border-left: 10px solid $error-red;
  padding: 15px;
  background: $ultra-light-gray;
  .glyphicon-remove-sign{
    color: $error-red;
    font-size: 20px;
    float:left;
  }
}

.Truckload-Haz-error-message {
  border-left: 10px solid #da0303;
  padding: 15px;
  background: #ececec;
  margin-bottom: 15px; }
.Truckload-Haz-error-message h3 {
  color: #da0303; }

//Transactional TL Customer GAQ Button Styling
.transactional-tl-button{
  float: left;
  width: 130px;
  height: 120px;
  margin-right: 20px;
  outline:none;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 5px #676767;
  display: inline-block;
}

.mode-divider {
  border-left: 1px solid #AAAEB0;
  height: 120px;
  display: inline-block;
  float: left;
  margin-right: 50px;
  margin-left: 30px;
}

.parcel-mode {
  display: inline-block;
}

.external-link {
  display: inline-block !important;
  height: 23px !important;
  width: 23px !important;
  padding-left: 3px;
  padding-bottom: 3px;
}

.parcel-icon {
  height: 54px;
  width: 54px;
  margin-bottom: 7px;
  padding-top: 10px;
}

.centering-icon{
  display: block;
  margin: auto;
}

.center-tl-button-text{
  display: inline-block;
  font-size: 20px
}
