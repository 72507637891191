$navisphereBluePrimaryLight: #108de0;
$navisphereBlue: #0078ae;
$validation-green: #7b9f1b;
$validation-yellow: #e8af01;
$error-red: #da0303;
$primaryGray: #676767;
$input-border-gray: #a9a9a9;
$placeholder-gray: #adadad;
$secondaryGray: #D6D6D6;
$disabled-gray: #ebebe4;
$ultra-light-gray: #ececec;
$white: #ffffff;
