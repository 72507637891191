.panel-freightmovement {
  min-height:150px;
}

.panel-heading-freightmovement {
  font-size: 14px;
  min-height:60px;
  font-weight: bold;
}


