/*** HEADER STYLES ***/
.navbar{
  background-color: #ffffff;
  &.single-ui-header {
    background-color: rgb(11, 47, 77);
    .container-fluid {
      @media (min-width: 1440px) {
        padding: 16px 48px 0 48px;
      }
      padding: 16px 40px 0 40px;
    }
    .single-ui-container-fluid {
      max-width: 1824px;
    }
    .navbar-header {
      margin-bottom: 8px;
    } 
    .navbar-search {
      width: 328px;
      display: flex;
      .form-control {
        height: 40px;
      }
      #headerSearch {
        border: 0px;
        padding: 8px 8px 8px 16px;
        height: 24px;
        box-sizing: content-box;
        border-radius: 4px 0px 0px 4px;
      }
      .btn-default {
        height: 40px;
        border-radius: 0px 4px 4px 0px;
        .glyphicon {
          font-size: 16px;
        }
      }
    }
    .navbar-brand {
      padding-top: 0px;
      img {
        height: 40px;
      }
    }
    .navbar-nav {
      li {
        margin-right: 32px;
        a {
          padding: 16px 0px;
          margin: 0px;
          font-size: 16px;
          font-weight: 400;
          text-transform: none;
          letter-spacing: 0.15px;
          line-height: 24px;
          height: 100%;
          color:rgb(54, 56, 58);
          border: none;
          &.active  {
            border-bottom: solid rgb(0, 113, 199)
          }
        }
      }
      .dropdown-menu {
        width: 244px;
        li {
          margin-right: 0px;
          a {
            color: rgb(17, 17, 18);
            font-size: 14px;
            letter-spacing: 0.5px;
            display: block;
            padding: 6px 16px 6px 8px;
            text-wrap: wrap;
            &:hover, &.active {
              color: #108de0;
              background-color: rgba(0, 113, 199, 0.08);
            }
          }
        }
      }
    }
    .single-ui-header-nav{
      display: flex;
      position: absolute;
      right: 0px;
      left: -1px;
      justify-content: center;
      background: #fff;
      ul {
        max-width: 1824px;
        margin: 0;
      }
    }
    .navbar-icons {
      float: unset !important;
      display: flex;
      justify-content: flex-end;
    }
    #app-chooser-dropdown {
      .navbar-btn {
        color: #FFF;
        margin: 4px;
        padding: 8px;
        border-radius: 100%;
        &:hover, &:focus {
          color: #FFF;
          background-color: rgba(0, 0, 0, 0.08);
          .icon {
            fill: #FFF;
          }
        }
        @media (max-width: 767px) {
          margin-top: 4px;
        }
      }
      .icon {
        fill: #FFF;
        width: 16px;
        height: 16px;
        margin: 0px 8px;
      }
    }
    #user-profile-dropdown {
      .navbar-btn {
        color: #FFF;
        border-radius: 26px;
        padding: 4px 16px;
        margin-left: 8px;
        margin-top: 4px;
        &:hover, &:focus {
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
      .icon {
        fill: #FFF;
      }
    }
    .userName {
      font-size: 14px;
      font-weight: 600;
      margin-left: 8px;
    }
    .navisphere-brand {
      @media (min-width: 1440px) {
        width: calc(100% + 96px);
        margin-left: -48px;
        padding-left: 48px;
        padding-right: 48px;
      }
      width: calc(100% + 80px);
      background-color: #FFF;
      margin-left: -40px;
      padding-left: 40px;
      padding-right: 40px;
    }
    .navbar-form {
      margin-top: 0px;
      margin-right: 4px;
      @media (max-width: 767px) {
        padding-right: 15px;
        .navbar-search {
          width: 100%;
          .form-control {
            border-radius: 4px 0px 0px 4px;
          }
          .input-group-btn {
            width: unset;
          }
        }
      }
    }
    .navbar-toggle {
      &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.08);
      }
      .icon-bar {
        background-color: #FFF;
      }
    }  

  }
}

.navbar-brand > img {
  height: 28px;
}

.navisphere-brand{
  color: $primaryGray;

  .dropdown-menu > li > a{
  	font-size: 12px;
  	color: $primaryGray;
  }

  // need this because we override the .dropdown-menu style for the typeaheads -____-
  .dropdown-menu{
    overflow: hidden;
    max-height: none;
  }
}

.navisphere-brand > li > a{
  height: auto;
  font-size: 12px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

// hover & focus state for nav options
.navisphere-brand.navbar-nav > li > a:hover,
.navisphere-brand.navbar-nav > li > a:focus,
.navisphere-brand .dropdown.open > a:hover,
.navisphere-brand .dropdown.open > a:focus {
  background-color: transparent;
  color: $navisphereBluePrimaryLight;
}


// active state
.navisphere-brand > li > a.active,
.navisphere-brand .dropdown.open > a.active,
.navisphere-brand > li > a.active:hover,
.navisphere-brand > li > a.active:focus {
  color: $navisphereBluePrimaryLight;
  font-weight: bold;
  border-bottom: solid $navisphereBluePrimaryLight;
  border-width: 0 0 5px 0;
  height: 50px;

  @media (max-width: 767px) {
      background: transparent;
      border-bottom: none;
      border-left: thick solid $navisphereBluePrimaryLight;
      text-align: left;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
  }
}

.newNotificationBadge {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

.newNotificationBadge + a {
  display: inline-block !important;
  padding-left: 10px !important;
}


// hover state for dropdown menu items
.nav.navisphere-brand .dropdown-menu > li > a:hover,
.nav.navisphere-brand .dropdown-menu > li > a:focus,
.navbar .navisphere-brand .open .dropdown-menu > li > a:hover,
.navbar .navisphere-brand .open .dropdown-menu > li > a:focus,
.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  color: $navisphereBluePrimaryLight;
  background: transparent;
  border-bottom: none;
}

.navisphere-brand.navbar-nav .open .dropdown-menu > li > a.active {
  font-weight: bold;
}

.navbar-default .navbar-nav > .open > a {
  background: transparent;
}

.navisphere-brand .open > a,
.navisphere-brand .open > a:hover,
.navisphere-brand .open > a:focus {
    border-color: $secondaryGray;
}

// navbar icons styling
.navbar-default .navbar-icons{
  color: $primaryGray;
  .caret {
    margin-left: 9px;
  }

  .navbar-btn{
    height: 34px;
  	background-color: transparent;
  	border: none;
  	padding: 4px 0px;
    margin: 8px 10px 0 0;
    color: $primaryGray;

    @media (max-width: 767px) {
      margin-top: 12px;
  }
  }

  .navbar-btn:hover,
  .navbar-btn:focus{
  	color: $navisphereBluePrimaryLight;
    border: none;
    background-color: white;
  }
  .icon{
    width: 23px;
    height: 23px;
    fill: $primaryGray;
  }

  .navbar-btn:hover .icon,
  .navbar-btn:focus .icon{
    fill: $navisphereBluePrimaryLight;
  }

  .dropdown-menu > li > a{
    color: $primaryGray;
    font-size: 14px;
  }

  .dropdown-menu{
  	top: 46px;
    margin-right: 10px;
    padding: 5px, 0;
  }

  .dropdown-menu .divider{
    padding-top: 0;
    padding-bottom: 0;
  }

  .open > a, .open > a:hover, .open > a:focus, .open > a:active, li > a:hover, li > a:focus{
    color: $navisphereBluePrimaryLight;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}

.app-chooser{
  width: 300px;
  cursor: default;

  li{
    padding: 0;
  }

  a {
    display: block;
    text-decoration: none;
    font-weight: 400;
    line-height: 1.42857;
    color: rgb(103, 103, 103);
    clear: both;
    white-space: nowrap;
    cursor: pointer;
    padding: 8px 15px;
  }

  a:hover {
    color: $navisphereBluePrimaryLight;
  }

  .current-app {
    color: $navisphereBluePrimaryLight;
    padding: 8px 12px;
    border-left: 3px solid $navisphereBluePrimaryLight;
  }
}

.user-account{
    min-width: 300px;
    padding: 0 5px;
    cursor: default;

    h4{
      margin: 0 0 5px 0;
    }

    p{
      margin: 0;
      font-size: 14px;
    }

    .circle-border{
      width: 45px;
      height: 45px;
      border: 1px solid $primaryGray;
      text-align: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;

      .initials {
        position: relative;
        top: 9px;
        font-size: 18px;
        color: $primaryGray;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
}

.navbar-default .navbar-toggle{
  margin-right: 0;
  border-color: transparent;
}

.navbar-search{
  .form-control{
    height: 34px;
  }

  .btn.btn-default{
    height: 34px;
    margin: 0;
    padding: 6px 12px;
    background-color: white;
    color: $primaryGray;
    border: none;

    .glyphicon{
      font-size: 23px;
      margin-right: 5px
    }
  }

  .btn.btn-default:focus, .btn.btn-default:hover, .btn.btn-default:active{
    color: $navisphereBluePrimaryLight;
    box-shadow: none;
  }
}

.navbar-form {
  border: 0;
  padding: 0 0 0 15px;
}

.pageStartingPoint {
  position: relative;
  margin-top: 95px;
  height: 5px;
}

.site-maintenance-banner-section {
  text-align: center;
  background-color: #F5F5F5;
  padding: 3px 0 8px 0;
  z-index: 1;
  width: 970px;
  margin-right: auto;
  margin-left:auto;
  @media (max-width: 992px) {
    width: 100%;
    padding-top: 8px;
  }
}

.site-maintenance-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid lightgray;
  border-left: 8px #005CB9 solid;
  background-color: #FFFFFF;
}

.site-maintenance-banner span.strong {
  font-weight: bolder;
}

.site-maintenance-banner-icon {
  margin-right: 10px;
  color: #E8AF01;
}

.site-maintenance-banner-header {
  font-weight: bold;
  margin-right: 3px;
}

.maintenance-banner-close-button {
  display:inline-block;
  margin-left:13px;
  color: #A2A2A2;
}

.beta-details-page-banner-section {
  text-align: center;
  background-color:  #3091bc solid;
  padding: 3px 0 8px 0;
  z-index: 1;
}

.beta-details-page-banner {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border: 1px solid #3091bc;
  border-left: 8px #3091bc solid;
  background-color: #FFFFFF;
  width: 77%;
  margin-top:45px;
  Cursor: pointer;
}

.beta-details-page-banner span.strong {
  font-weight: bolder;
}

.beta-details-page-banner-icon {
  margin-right: 10px;
  color: #3091bc;
}

.beta-details-page-banner-header{
  font-weight: bold;
  margin-right: 3px;
}

.page-retirement-banner-icon {
  top: 3px;
  font-size: 18px;
  margin-right: 10px;
  color: #005CB9;
}

.page-retirement-banner-text {
  font-size: 14px;
}