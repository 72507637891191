.search-invoices-form {
  display: flex;
  flex-direction: row;
  margin-top: 16px;

  & > div {
    flex: 1 1 0;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
