.orders-overview-report-style .panel-left-space{margin-left:5px;}
.orders-overview-report-style .control-left-space{margin-left:15px;}
.orders-overview-report-style .control-top-space{margin-top:10px;}
.orders-overview-report-style .nodata{color:red;}
.orders-overview-report-style .generating{color:blue;}

select[multiple], select[size] {
  width: 100%;
  max-height: 140px;
}

.required-field-block select {
  padding-left: 25px;
}

.orders-overview-report-style input[type=checkbox] {
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 1;
  top: 2px;
}

.orders-overview-report-style div.filters {
  min-height: 200px;
}

.orders-overview-report-style .datepicker-container {
  padding-top: 15px;
}

.orders-overview-report-style .radio + .radio {
  margin-top: -10px;
}

.orders-overview-report-style input[type=radio] + label {
  padding: 0;
}

.orders-overview-report-style .radio {
  margin-top: 30px;
}

.orders-overview-report-style .account label {
  margin-top: 15px;
}

.orders-overview-report-style .datepicker-container.padbot {
  padding: 5px 0 15px 0;
}

.orders-overview-report-style .datepicker-container .form-control[readonly] {
  background-color: #fff;
}

.orders-overview-report-style .filters .required-field-block {
  margin-top: -1px;
  margin-left: -16px;
}

.orders-overview-report-style h4.required{
  position: relative;
  &::after {
    background: transparent;
    color: #da0303;
    font-size: 12px;
    padding-left: 5px;
    line-height: 15px;
    top: 5px;
    left: 0;
    border-radius: 50%;
    content: '*';
  }
}
