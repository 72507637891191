// Responsive SVG
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

.chart-filled
{
  fill: steelblue;
  //fill:limegreen;
}
.chart-empty
{
  fill: #dedede;
}

.needle, .needle-center
{
  fill: #464A4F;
}

.liquidFillGaugeText {

  font-family: Helvetica; font-weight: bold;

}
