.downloadCheckDefault {

  font-size: 80%;
  background-color: transparent;
  padding-right:5px;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;

}

.downloadButton {
  margin-right:1px;
  margin-bottom:0px;
};

.downloadDropDownBorder {
  border: $navisphereBlue 1px solid;
  margin-right:0px;
}


