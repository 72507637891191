@import 'variables.scss';

$switch-height: 26px;
$switch-width: 62px;
$circle-diameter: $switch-height * 0.8;
$top-distance: ($switch-height - $circle-diameter) / 2;
$side-distance: ($switch-width - $circle-diameter) - $top-distance;
$font-size: 14px;

.switch {
  text-transform: uppercase;
  width: $switch-width;
  height: $switch-height;
  color: $navisphereBlue;
  transition: 0.5s ease-out all;
  -webkit-transition: 0.5s ease-out all;
}

.switch small{
  background-color: $navisphereBlue;
  height: $circle-diameter;
  width: $circle-diameter;
  top: $top-distance;
  left: $top-distance;
}

.switch.checked {
  background-color: $navisphereBlue;
  border-color: $navisphereBlue;

}
.switch.checked small {
  left: $side-distance;
  background-color: white;
}

.switch .off {
  color: $navisphereBlue;
}

.switch-text{
  line-height: $font-size;
}

.switch .on, .switch .off{
  top: ($switch-height - $font-size) / 2;
  line-height: $font-size + 1;
}