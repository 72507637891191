/***** MAP *****/
.map-container{
  overflow: hidden;
  position: relative;
  height: calc(100vh - 101px);
  margin-top: -35px;
}

.map{
  overflow: hidden;
  height: 100%;
  width: 100%;
}

/***** SEARCH *****/
.map-search .input-group-btn button{
  height: 40px;
  margin: 0;
  padding: 0px 15px;
}

.map-search-clear{
  position: absolute;
  z-index: 1001;
  margin-left: -20px;
  margin-top: 11px;
}

.map-search-clear .glyphicon{
  font-size: 13px;
}

/***** SIDE PANEL *****/
.side-panel{
  height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

.map-panel-header{
    position: relative;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    div{
      width: 75%;
      height: 40px;
    }

    h3{
      margin: 0;
      color: white;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      align-self: center;
    }

    a{
      color: white;
    }

    a:hover{
      color: whitesmoke;
      cursor: pointer;
    }

    img{
      width: 100%;
      height: 100%;
      max-width: 30px;
      max-height: 23px;
    }
}

.map-panel-item{
  padding: 10px;
}

.fixed-bottom-left{
  position: fixed;
  left: 15px;
  bottom: 28px;
}

.btn-oval{
  background-color: #676767;
  color: #fff;
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  margin: 0;

  img{
    width: 20px;
    height: 20px;
    margin-left: -8px;
    margin-right: 3px;
    border-radius: 50%;
  }

  &:hover, &:active, &:focus{
    background-color: #484848;
    color: #ffffff;
  }
}

.map-selector:hover{
  cursor: pointer;
  background-color: #9999BB;
}

.active-selector{
  background-color: #9999BB;
  border: none;
  outline: 0;
}

/***** INFOBOX *****/
.max-half-height{
  max-height: 50%;
}

.max-full-height{
  max-height: 100%;
}

.infobox-item{
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.infobox-data{
  overflow-y:auto;
  overflow-x:hidden;
  height:100%;
}

.infobox-data-button{
  border-color: #0078ae;
  background-color: #ffffff;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-button:hover{
  border-color: #0078ae;
  background-color: #f5f5f5;
  color: #0078ae;
  border-radius: 5px;
}

.infobox-data-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25em;

  span{
    align-self: center;
  }
}

.infobox-data-row.pin{
  justify-content: flex-start;
  margin-bottom: 0px;
}

.infobox-data-pin{
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  align-self: center;
  z-index: 1000;
}

.infobox-data-dotted-line{
  height: 100%;
  min-height: 10px;
  width: 20px;
  min-width:20px;
  background: url("../app/assets/img/InfoPanel_LocationDot.svg") center 5px repeat-y;
  background-size: 4px 8px;
}

.infobox-data-item{
    border-bottom: thin solid #ECECEC;
    font-size: 13px;
}

/***** MAP MESSAGE OVERLAY *****/
.message-overlay{
  position: fixed;
  bottom: 28px;
  background-color: #676767;
  color: white;
  z-index: 1000;
  border-radius: 5px;
}

/***** FOOTER FOR MAP *****/
.map-footer{
  bottom: 8px;
  left: 55px;
}

// stop microsofts display of infobox
.MicrosoftMap .Infobox{
  display: none;
}