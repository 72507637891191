.wizard-selections {
  position: relative;
  height: 110px;
  width:324px;
  padding:4px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index:10;
  outline: none !important;

}

.wizard-selections:before,
.wizard-selections:after{
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections.active-widget:before {
  border-width: 1px;
  right:-22px;
  border-left: 21px solid #979797;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 54px solid transparent;
  border-bottom: 54px solid transparent;
}

.wizard-selections-narrow{
  position: relative;
  height: 90px;
  width:250px;
  padding:2px;
  border: 1px solid #ddd;
  background: whitesmoke;
  z-index:10;
  outline: none !important;

}

.wizard-selections-narrow:before,
.wizard-selections-narrow:after{
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  z-index: 10;
}

.wizard-selections-narrow.active-widget:before {
  border-width: 1px;
  right:-22px;
  border-left: 21px solid #979797;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-selections-narrow.active-widget:after {
  border-left: 20px solid white !important;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.wizard-area.active-widget {
  margin-left:3px;
}

.wizard-area {
  min-height:200px;
  padding: 4px 4px 40px;
  border-top: 1px solid rgba(151, 151, 151, 0.29);
  margin-bottom:1px;
  z-index:10;
  background-color:white;

  &:nth-last-child(2) {
    padding-bottom: 20px;
  }
}

.wizard-widget-scroll-area {
  background-color: #fafafa;
  min-height: 440px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-widget-scroll-area-long {
  background-color: #fafafa;
  min-height: 540px;
  height: calc(75vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.wizard-selection-area{
  padding-left:10px;
  font-weight:bold;
}

.wizard-selection-area-header{
  line-height:26px;
  padding-left:10px;
  vertical-align: middle;
}

.wizard-selection-area-header-center{
  margin-top:14px;
}

.validator-icon {
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 8px 0;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid $primaryGray;
  font-size: 150%;
  background-color: whitesmoke;
  color: transparent;
}

.validator-icon.complete{
  color: white;
  background-color:#729514;

}

.active-widget{
  background-color: white;
  border: 1px solid #979797;
  outline: none !important;
}

.wizard-active-widget{
  border: 1px solid whitesmoke;
  margin-bottom:1px;
  z-index:10;
  outline: none !important;
}

.create-report-form {
  overflow: hidden;
  height:100%;
  width:100%;
}

.account-selection-display{
  overflow: auto;
  max-height:180px;
}

.saved-report-height {
  min-height: 80px;
}

.account-selected{
  font-size: 300%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.account-selected.selected {
  color: $navisphereBlue;
}

.account-select-all{
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-remove-all {
  font-size: 140%;
  background-color: transparent;
  line-height: 22px;
  margin-right: 10px;
}

.account-widget-scroll-area{
  overflow-y: auto;
  max-height: 230px;
}

.account-panels{
  font-size:14px;
  line-height:20px;
  margin-bottom:5px;
  text-align:left;
  border:1px solid $secondaryGray;
}

.header-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.saved-report-icons{
  height: 30px;
}

.saved-report-icons span{
  font-size: 130%;
  background-color: transparent;
  line-height: 28px;
  margin-right: 10px;
}


.date-picker-label{
  line-height:84px;
  vertical-align: middle;
  white-space: nowrap;
}

.split-button{
  height:26px;
  margin-bottom:0px;
}

.report-button{
  color: $navisphereBlue;
}

.report-button:hover{
  color: #00008a;
}

.location-selection{
  font-size: 200%;
  background-color: transparent;
  color: white;
  text-shadow: -1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey;
  line-height: 50px;
  margin-right: 10px;
}

.location-selection:hover {
  color: $navisphereBlue;
}

.location-selected{
  font-size: 150%;
  background-color: transparent;
  color: $navisphereBlue;
  line-height: 50px;
  margin-right: 10px;
}

.location-add{
  top:40px;
  position:absolute;
  font-size:10px;
  color:#0078ae;
  margin-left: 3px;
}

.location-remove {
  top:40px;
  position:absolute;
  font-size:10px;
  color:#0078ae;
  margin-left: -6px;
}

.location-widget-scroll-area{
  overflow-y: auto;
  max-height: 380px;
}


.validator-icon-sm {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  margin: 4px 0;
  padding-left:2px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid $primaryGray;
  font-size: 76%;
  background-color: $ultra-light-gray;
  color: transparent;
}

.validator-icon-sm.complete {
  color: $white;
  background-color: $validation-green;
}

.report-subscription-summary {
  display: table;

  & > div {
    display: table-row;

    & > div {
      display: table-cell;

      &:first-child {
        color: gray;
        text-align: right;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.subscription-icon {
  height:20px;
  width:20px;
}

.error-icon-sm {
  position: absolute;
  top: 4px;
  left: 0px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin: 4px 0;
  padding-left:2px;
  -moz-border-radius: 144%;
  -webkit-border-radius: 144%;
  border-radius: 144%;
  border: 1px solid $primaryGray;
  font-size: 150%;
  border: none;
  background-color: transparent;
  color: $error-red;
}

.close-icon-sm {
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: 6px 22px;
  border: none;
  font-size: 100%;
  background-color:$white;
  color: $primaryGray;
}

.toast-inner-container {
  padding: 0px !important; 
  top:-60px; 
  height:60px; 
  background-color:white; 
  width:300px;
  margin-right: 40px !important;
}

.toast-message {
  margin-top:6px; 
  color:black; 
  font-size:14px; 
  font-weight: bold;
  padding-left:0px;
}

.toast-sub-message {
  color:black; 
  font-size:10px; 
  font-weight: 600;
}

.toast-success-left {
  width:10px; 
  height:60px; 
  background-color:$validation-green;
}

.toast-error-left {
  width:10px; 
  height:60px; 
  background-color:$error-red;
}

.toast-success-border {
  border: 1px solid $validation-green; 
}

.toast-error-border {
  border: 1px solid $error-red; 
}

.toast-icon{
  padding-left:0px !important; 
  margin-left:0px;
}