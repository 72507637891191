.dashboard-widget-area {
  margin: 10px 0 30px;
  min-height: 200px;
}
.widget-container {
  float: left;
  display: inline-block;
  width: 33%;
  padding-bottom: 1em;
}
.widget {
  margin: 0 1em 0 0;
  background-color: white;
  border: solid 1px #676767;;
  position: relative;
  height: 100%;
}

.btn-toolbar{
  padding-top: 20px;
  float:left;
}

#subheader h2.text-left {
  float: left;
  padding-right: 10px;
}

#widget-dashboard .btn-toolbar .btn-warning{
  background-color: #f47b20;
}

#widget-dashboard .btn-toolbar .btn {
  font-size: 10px;
  padding: 5px;
}

#widget-dashboard .btn-toolbar .btn-group,
#widget-dashboard .btn-toolbar .btn {
  float: right;
}

#widget-dashboard {
  .widget-header {
    overflow: hidden;
    background-color: #676767;
    position: relative;
    .widget-title {
      vertical-align: middle;
      color: $white;
      font-size: 16px;
    }
    .glyphicon {
      color: $white;
      opacity: 1;
      float: none;
      margin-right: 10px;
      &:hover {
        cursor: inherit;
      }
    }
    .widget-controls {
      position: absolute;
      top: 5px;
      right: 5px;
      .widget-controls-text{
        color: $white;
        &:hover{
          color: $secondaryGray;
        }
      }
      .glyphicon {
        cursor: pointer;
        float: right;
        opacity: 1;
        margin-left: 10px;
        &:hover {
          opacity: 1;
          color: $secondaryGray;
        }
      }
    }
    .label {
      display: inline-block;
      vertical-align: middle;
    }
  }
}


.widget-header form.widget-title {
  display: inline;
}
.widget-header form.widget-title input.form-control {
  width: auto;
  display: inline-block;
}
.widget-content {
  overflow: hidden;
}
.widget .widget-ew-resizer {
  position: absolute;
  width: 5px;
  right: -2px;
  height: 100%;
  top: 0;
  cursor: ew-resize;
}
.widget .widget-s-resizer {
  cursor: ns-resize;
  height: 5px;
  width: 100%;
  bottom: -7px;
  left: 0;
}
.widget .widget-resizer-marquee {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.remove-layout-icon {
  vertical-align: text-top;
  cursor: pointer;
  opacity: 0.3;
}
.remove-layout-icon:hover {
  opacity: 1;
}
.layout-title {
  display: inline-block;
}

#widget-dashboard .panel-body {
  padding: 0;
}

.dashboard-item {
  margin-top: 0;
  height: 100%;
  overflow: auto;
}

.dashboard-item-content {
  border: 1px solid transparent;
  background-color: #fff; /* bjorn */
}


// TRACKING WIDGET
#widget-dashboard .tracking-number-widget-container .tracking-number .btn {
  max-width: 100%;
  line-height: 18px;
  word-wrap: normal;
  white-space: normal;
}

.tracking-number-widget-container {
  margin: 30px 10px;
}

.tracking-number {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.tracking-number input {
  background-color: #f9f9f9;
  height: 60px;
  padding-left: 20px;
  font-size: 18px;
}

.tracking-number button {
  height: 60px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 0;
}

.tracking-number button:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #0078ae;
  border-width: 10px;
  margin-top: -10px;
}

.tracking-number button:hover:after {
  border-right-color: #286090;
}

.tracking-number button:focus:after {
  border: none;
}

#widget-dashboard .tracking-number-widget-container .create-order-btn {
  max-width: 600px;
  margin: 0 auto;
}

#widget-dashboard .tracking-number-widget-container .create-order-btn .btn {
  width: 100%;
  margin: 20px 0;
  text-transform: uppercase;
  word-wrap: normal;
  white-space: normal;
  line-height: 18px;
}

#widget-dashboard .create-order-btn .btn svg {
  fill: #0078ae;
  vertical-align: middle;
  color: #fff;
  margin-bottom: 3px;
}

.tracking-number-widget-container .help-block {
  max-width: 600px;
  margin: 5px auto 0 auto;
}

// RECENT ACTIVITY WIDGET
.dashboard-item-content {
  padding: 5px;
}

.widget-activity-row {
  width: 100%;
  min-height: 40px;
  border: 1px solid #676767;
  border-radius: 6px;
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.widget-activity-row .fix-fluid {
  float: left;
  height: auto;
  overflow: hidden;
}

.widget-activity-row .fix-fluid.left {
  width: 67%;
  display: flex;
  justify-content: space-between;
}

.widget-activity-row .fix-fluid.right {
  width: 33%;
}


.widget-activity-row .fix-fluid div {
  display: inline-block;
  vertical-align: middle;
}

.widget-activity-row .order-number a {
  cursor: pointer;
}

.dashboard-item-content .vertical-align {
  display: flex;
  align-items: center;
  height: 50px;
}

.dashboard-item-content .at-a-glance .vertical-align {
  height: 40px;
  justify-content: center;
}

#widget-dashboard .vertical-align div {
  box-sizing: border-box; /* 2 */
  max-width: 100%; /* 1 */
}

.dashboard-item .date {
  background-color: #676767;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  width: 50px;
  height: 50px;
  position: relative;
  padding-top: 10px;
  float: left;
}

.dashboard-item .date:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #676767;
  border-width: 8px;
  margin-top: -8px;
}

.dashboard-item .date span {
  display: block;
  text-align: center;
}

.dashboard-item .date span.day {
  font-size: 26px;
  line-height: 26px;
  height: 26px;
}

.dashboard-item .date span.month {
  font-size: 10px;
  line-height: 10px;
  height: 10px;
}

.widget-activity-row .order-number {
  min-width: 60px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
}

.widget-activity-row .order-pickup span {
  justify-content: flex-end;
  text-align: right;
}

.widget-activity-row .order-pickup.calc {
  width: calc(100% - 150px);
  max-width: calc(100% - 130px);
  float: left;
  overflow: hidden;
}

.widget-activity-row .to-arrow {
  width: 18px;
  padding-left: 3px;
}

.widget-activity-row .to-arrow svg {
  fill: #676767;
  width: 16px;
  height: 16px;
}

.widget-activity-row .deliver-to.calc {
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  float: right;
  padding-left: 2px;
  overflow: hidden;
}

.widget-activity-row .remove-row {
  position: absolute;
  top: -2px;
  right: -2px;
  color: #da0303;
  padding: 4px 4px 8px 8px;
}

.widget-activity-row .event-status {
  padding: 0 15px 0 10px;
  overflow: hidden;
}


// AT A GLANCE WIDGET
#at-a-glance-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.at-a-glance {
  width: 120px;
  height: 120px;
  border: 1px solid #676767;
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin: 10px;
}

.at-a-glance:focus {
  outline: none;
  box-shadow: 0 0 2pt 1pt #0078ae;
}

.at-a-glance .number {
  font-size: 28px;
  font-weight: bold;
  color: #0078ae;
  padding-top: 8px;
}

.at-a-glance .title {
  background-color: #676767;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 14px;
  padding: 0 2px;
  word-wrap: normal;
  white-space: normal;
}

.at-a-glance:hover .title {
  background-color: #0078ae;
}

.at-a-glance .title:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #676767;
  border-width: 10px;
  margin-left: -10px;
}

.at-a-glance:hover .title:after {
  border-bottom-color: #0078ae;
}

// MY NOTIFICATIONS WIDGET
#widget-dashboard .widget-header .badge {
  background-color: #0078AE;
  margin-left: 5px;
}


// WIDGET SETTINGS
.modal-header h3 small {
  display: none;
}

.data-hover {
  cursor: default;
}


span.city-state {
  font-size: 10px;
  display: block;
  width: 100%;
}

.widget-subscriptions .col-sm-10 {
  padding-top: 2px;
}

.widget-subscriptions .control-label {
  text-transform: uppercase;
}

.widget-subscriptions input[type=checkbox] + label {
  padding: 0;
  line-height: 28px;
}

.widget-subscriptions input[type=checkbox] + label::before {
  margin-right: 3px;
  line-height: 28px;
  font-size: 20px;
}

.widget-subscriptions .radio {
  padding: 0;
}

/* OVERRIDE HOVER FOR "OK" BUTTON */
body.modal-open .modal-dialog .modal-footer button.btn-primary:hover {
  color: #0078ae;
  background-color: #ffffff;
  border-color: #0078ae;
}
